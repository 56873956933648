import { useStaticQuery, graphql } from "gatsby"
const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            siteId
            email,
            telefono
            telefonoYoVoy
            direccion
            AFILIADO_ID
          }
        }
      }
    `
  )
  
  return site.siteMetadata
}

export default useSiteMetadata
