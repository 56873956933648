exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-pedidos-js": () => import("./../../../src/pages/pedidos.js" /* webpackChunkName: "component---src-pages-pedidos-js" */),
  "component---src-pages-pedidos-lista-js": () => import("./../../../src/pages/pedidos-lista.js" /* webpackChunkName: "component---src-pages-pedidos-lista-js" */),
  "component---src-pages-pedidos-yovoy-js": () => import("./../../../src/pages/pedidos/yovoy.js" /* webpackChunkName: "component---src-pages-pedidos-yovoy-js" */)
}

