import React from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

import { CardProvider } from "acciondigital-basic-theme/src/components/CartContext"
// import { crearCartProvider } from "acciondigital-basic-theme/src/components/CartContext"
import AuthProvider from "acciondigital-basic-theme/src/context/AuthContext"
// import { calcularTotal } from "acciondigital-commons/cartCommonsUtils"
import useSiteMetadata from "acciondigital-basic-theme/src/hooks/useSiteMetadata"

const queryClient = new QueryClient()

// const sitio = process.env.GATSBY_NETLIFY_SITE_ID
// console.debug("🚀 ~ file: rootElement.js:11 ~ sitio:", sitio)

// const reglas = [
//   (cartInfoNew, itemsNew, newCartInfoExtra, newControl)=>{
//     console.log({itemsNew})
//     const subtotal = calcularTotal(itemsNew)
//     if(subtotal < 1000){
//       newControl.descuento = 0
//     } else if(subtotal >= 2000){
//       newControl.descuento = 20
//     } else if(subtotal >= 1000){
//       newControl.descuento = 10
//     }
//   }
// ]

// const CardProvider = crearCartProvider(reglas)

{/* <AuthProvider sitio={sitio} >
  </AuthProvider> */}
export const RootElement = ({ children }) => {
  const { siteId } = useSiteMetadata()
  return (
    <AuthProvider sitio={siteId}>
      <QueryClientProvider client={queryClient}>
        <CardProvider sitio={siteId} saveToFirebase={true} vigenciaDiasDefault={-1}>
          {children}
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </CardProvider>
      </QueryClientProvider>
    </AuthProvider>
  )
}

export default RootElement
